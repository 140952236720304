<template>
  <!-- <div style="margin: 12px 24px"> -->
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="48">
          <el-col :md="8" :sm="24">
            <el-form-item label="班级名称：">
              <el-input
                v-model="queryParam.className"
                placeholder="请输入"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :md="8" :sm="24">
            <el-form-item label="班级状态：">
              <el-select
                v-model="queryParam.isFinish"
                class="w100"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in stateArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :md="8" :sm="24">
              <el-form-item label="班级校区：">
                <el-select
                  v-model="queryParam.campusIdSet"
                  class="w100"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in schoolArray"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="24">
              <el-form-item label="日期区间：">
              <date-picker ref="dataPicker" :dateTime="queryParam.dateTime" @updateView="datetTimeGet"></date-picker>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
          ></search-query>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex">
        <span>课程评价</span>
        <div class="flex_1"></div>

        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '校区'">
                {{scope.row.campusInfo.name}}
              </template>
              <template v-else-if="item.label === '班级名称'">
                <span v-if="scope.row.classInfo != null">{{scope.row.classInfo.name}}</span>
              </template>
              <template v-else-if="item.label === '上课时间'">
                <span>{{ scope.row.start_time | formatDateStart }}</span>
                <span>{{ scope.row.end_time | formatDateEnd }}</span>
              </template>
              <template v-else-if="item.label === '上课老师'">
                {{scope.row.teacherInfo.name}}
              </template>
              <template v-else-if="item.label === '已评价'">
                {{scope.row.hasLessonLength}}/{{scope.row.lessonStudentList.length}}
              </template>
              <template v-else-if="item.label === '操作'">
                <span class="el-dropdown-link" @click="gotoDetail(scope.$index)">
                   去评价
                  </span>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right;margin-right: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import getMyCampus from '@/mixins/getMyCampus'
  import { syncRefreshList } from '@/api/senate/class'
  import getStateColor from '@/mixins/getStateColor'
  import { formatDateYMD } from '@/utils/formatDate'
  import { teacherLessonList,getScopes} from '@/api/senate/class'
  import datePicker from '@/components/date-picker'

  // 默认展示列
  const baseColSetting = [
    {
      label: '校区',
      prop: 'campusName',
      state: true,
      align: 'center',
      width: '120'
    },
    {
      label: '班级名称',
      prop: 'name',
      state: true,
      align: 'center',
      width: '180',
    },
    {
      label: '上课时间',
      prop: 'open_date',
      state: true,
      sortable: true,
      align: 'center',
      width: '150'
    },
    {
      label: '上课老师',
      prop: '',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '已评价',
      prop: '',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '100'
    },
  ]
  export default {
    components: { TextButton, SearchQuery ,datePicker},
    mixins: [getMyCampus, getStateColor],
    created() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.queryParam.dateTime = [formatDateYMD(start),formatDateYMD(end)]
      this.minStartTime = formatDateYMD(start)
      this.maxStartTime = formatDateYMD(end)
      this.teacherLessonList()
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        dataList: [],
        minStartTime: '',
        maxStartTime: '',
        teacherDefaultArray:[],
        studentList:[],
        lessonCommentBeans:[],
        chooseDate: [],
        tableKey: 1,
        // 查询参数
        queryParam: {isFinish:''},
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        stateArray: [
          {
            label: '全部状态',
            value: ''
          },
          {
            label: '已评价',
            value: 1
          },
          {
            label: '未评价',
            value: 2
          }
        ],
        classOrderBy: 'openDate', // 排序依据
        classIsAsc: false, // 默认为升序
      }
    },
    methods: {
      datetTimeGet(data) {
        if(data === null) {
          this.minStartTime = ''
          this.maxStartTime =  ''
        }else {
          this.minStartTime = data[0]
          this.maxStartTime =  data[1]
        }

      },
      search() {
        this.pagination.currentPage = 1
        this.teacherLessonList()
      },

      async syncRefreshList() {
        let nowDate = new Date()
        const res = await syncRefreshList({
          minUpdateTime: formatDateYMD(nowDate),
        })
        this.search()
      },

      // 班级列表排序
      tableSortAll(column) {
        switch (column.prop) {
          case 'courseName':
            this.classOrderBy = 'courseName'
            break
        }
        switch (column.order) {
          case 'ascending':
            this.classIsAsc = true
            break
          case 'descending':
            this.classIsAsc = false
        }
        this.teacherLessonList()
      },


      updateView(data) {
        this.advanced = data
      },

      // 清空选项
      clearQuery() {
        this.queryParam = {}
        this.$refs.dataPicker.time = []
        this.maxStartTime = ''
        this.minStartTime = ''
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      getQueryDate () {
        if (this.chooseDate.length === 0) {
          this.minStartTime = ''
          this.maxStartTime = ''
        } else {
          this.minStartTime = this.datefmt(this.chooseDate[0]) + ' 00:00'
          this.maxStartTime = this.datefmt(this.chooseDate[1]) + ' 23:59'
        }
      },
      // dateKey 时间处理
      datefmt (date) {
        let res = ''
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        let day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        res = `${year}-${month}-${day}`
        return res
      },
      gotoDetail(index) {
        if(this.dataList[index].lessonStudentList.length === 0) {
          $msg("暂无需要评论的学生" ,2)
          return false
        }
        this.getScopes(index)
      },
      async getScopes(index) {
        const res = await getScopes({})
        this.teacherDefaultArray = res.body.tcomment
        this.teacherDefaultArray.forEach((item,index) => {
          if(item.value == null) {
            item.value = 1
          }
        })
        this.studentList = this.dataList[index].lessonStudentList
        this.lessonCommentBeans = this.dataList[index].lessonCommentBeans
        this.studentList.forEach((item,index) => {
          let thembArray = []
          this.teacherDefaultArray.forEach((item,index)=> {
            let object = {}
            object.scopeName = item.scopeName
            object.value = item.value
            object.maxValue = item.maxValue
            thembArray.push(object)
          })
          item.tcommentScopes = thembArray
          item.tState = "2"
          item.tcomment = ""
        })
        this.lessonCommentBeans.forEach((item,index) => {
          this.studentList.forEach((student,idnex) => {
            if(item.studentId === student.student_id) {
              student.tState = item.tState
              student.tcomment = item.tcomment
              if(item.tcommentScopes != null) {
                item.tcommentScopes.forEach((item,index) => {
                  if(item.value !== null) {
                    item.value = parseInt(item.value)
                  }else {
                    item.value = 1
                  }
                })
                student.tcommentScopes = item.tcommentScopes
              }
            }
          })
        })
        this.$router.push(
          {
            path:"/giveLesson/assessmentdetail",
            query: {
              studentList:JSON.stringify(this.studentList),
            }
          }
        )
      },
      // 获取课程评价列表
      async teacherLessonList () {
        const res = await teacherLessonList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          minStartTime:this.minStartTime,
          maxStartTime:this.maxStartTime,
          finishedStatus:"已上课",
          campusIdSet:this.queryParam.campusIdSet,
          isAttend:"是",
          className:this.queryParam.className,
          isAssess:this.queryParam.isFinish
        })
        this.dataList = res.body.list
        this.dataList.forEach((item) => {
          let count = 0
          item.lessonCommentBeans.forEach((value => {
            if(value.tState === '1') {
              count ++;
            }
          }))
          item.hasLessonLength = count
        })
        this.pagination.total = res.body.total
      },
      // 分页
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.teacherLessonList()
      },
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.teacherLessonList()
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "~@/style/container.scss";
</style>
